<template>
    <div class="profile_notes">
        <div class="title">
            <h3>Progress</h3>
            <div class="right_side">
                <div class="search_panel mr-3">
                    <div class="form-field">
                        <input type="text" name="search" placeholder="Search...">
                    </div>
                    <button class="search_btn">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
                <PageItem/>
            </div>
        </div>            
        <ul class="card_listing">
            <li>
                <NotesCard v-bind:action="actionObj" :perform="editFunc">
                    <h4 class="mb-3">Test Progress:</h4>
                    <h5>Submissions</h5>
                    <p>0/1</p>
                    <h5>Assigned on:</h5>
                    <span class="date">03/07/22 | 04:37 pm</span>
                </NotesCard>
            </li>
            <li>
                <NotesCard v-bind:action="actionObj" :perform="editFunc">
                    <h4 class="mb-3">Test1 Progress:</h4>
                    <h5>Submissions</h5>
                    <p>0/1</p>
                    <h5>Assigned on:</h5>
                    <span class="date">03/07/22 | 04:37 pm</span>
                </NotesCard>
            </li>
            <li>
                <NotesCard v-bind:action="actionObj" :perform="editFunc">
                    <h4 class="mb-3">Test2 Progress:</h4>
                    <h5>Submissions</h5>
                    <p>0/1</p>
                    <h5>Assigned on:</h5>
                    <span class="date">03/07/22 | 04:37 pm</span>
                </NotesCard>
            </li>
        </ul>
        <div v-if="viewProgress" class="edit_modal">
            <div class="edit_section p-0">
                <div class="form_body">
                    <h2 class="title_header">Progress report<button class="close_btn" @click="viewProgress = false"><i class="fas fa-times"></i></button></h2>
                    <div class="form_content">
                        <div class="habitCard">
                            <div class="header">
                                <div class="user_info">
                                    <img src="@/assets/images/table-user.png" alt="">
                                    <div>
                                        <h6>John Doe</h6>
                                        <p>Started Tracking</p>
                                    </div>
                                </div>
                                <div class="submission">
                                    <h6>0/2</h6>
                                    <p>Submission</p>
                                </div>
                            </div>
                            <ul class="tracking_list">
                                <li>
                                    <div class="track_box">
                                        <h6>Compliance Score: <span>0%</span></h6>
                                        <div class="ratios">
                                            <i class="far fa-star"></i>
                                            <i class="far fa-star"></i>
                                            <i class="far fa-star"></i>
                                            <i class="far fa-star"></i>
                                            <i class="far fa-star"></i>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="track_box">
                                        <h6>Habit Score: <span>0%</span></h6>
                                        <div class="ratios">
                                            <i class="far fa-star"></i>
                                            <i class="far fa-star"></i>
                                            <i class="far fa-star"></i>
                                            <i class="far fa-star"></i>
                                            <i class="far fa-star"></i>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="track_box">
                                        <h6>Best Streak: <span>0 Days</span></h6>
                                        <div class="ratios">
                                            <i class="far fa-star"></i>
                                            <i class="far fa-star"></i>
                                            <i class="far fa-star"></i>
                                            <i class="far fa-star"></i>
                                            <i class="far fa-star"></i>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <ul class="habit_list">
                                <li>
                                    <HabitCards v-bind:options="progressObj" title="Progress 1"/>
                                </li>
                                <li>
                                    <HabitCards v-bind:options="progressObj" title="Progress 2"/>
                                </li>
                            </ul>
                            <ul class="proofs">
                                <li>Submitted Proofs: --</li>
                                <li>History: --</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="pagination mt-2">
            <ul>
                <li>1</li>
                <li class="active">2</li>
                <li>3</li>
                <li>4</li>
                <li><i class="fas fa-ellipsis-h"></i></li>
            </ul>
        </div>
</template>

<script>
import PageItem from './PageItem.vue';
import NotesCard from './NotesCard.vue';
import HabitCards from './HabitCards.vue'
export default{
    name: 'ProfileHabit',
    components:{
        PageItem,
        NotesCard,
        HabitCards
    },
    data(){
        return{
            viewProgress: false,
            actionObj: [
                {
                    icon: "fa-search-plus",
                    label: "View",
                },
            ],
            progressObj: [
                {
                    label: "Best Streak",
                    value: "0 Days",
                },
                {
                    label: "Habit Score",
                    value: "0%",
                },
            ]
        }
    },
    methods:{
        editFunc(){
            this.viewProgress = true;
        }
    }
}
</script>